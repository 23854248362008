import * as React from "react"
const GreenCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
  >
    <path
      fill="#389E0D"
      d="M16.382 9.185h-1.1a.746.746 0 0 0-.606.312l-3.685 5.109-1.668-2.316a.75.75 0 0 0-.608-.311H7.616a.188.188 0 0 0-.152.297l2.92 4.05a.746.746 0 0 0 1.212 0l4.936-6.844a.187.187 0 0 0-.15-.297Z"
    />
    <path
      fill="#389E0D"
      d="M12 2.411c-5.798 0-10.5 4.702-10.5 10.5 0 5.799 4.702 10.5 10.5 10.5s10.5-4.701 10.5-10.5c0-5.798-4.702-10.5-10.5-10.5Zm0 19.22a8.72 8.72 0 0 1-8.719-8.72A8.72 8.72 0 0 1 12 4.193a8.72 8.72 0 0 1 8.719 8.718A8.72 8.72 0 0 1 12 21.631Z"
    />
  </svg>
)
export default GreenCheck
