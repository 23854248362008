import React from "react";
export const ToolTip = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className="-mb-0.5"
    >
      <path
        d="M8.5 1C4.63438 1 1.5 4.13438 1.5 8C1.5 11.8656 4.63438 15 8.5 15C12.3656 15 15.5 11.8656 15.5 8C15.5 4.13438 12.3656 1 8.5 1ZM8.5 13.8125C5.29063 13.8125 2.6875 11.2094 2.6875 8C2.6875 4.79063 5.29063 2.1875 8.5 2.1875C11.7094 2.1875 14.3125 4.79063 14.3125 8C14.3125 11.2094 11.7094 13.8125 8.5 13.8125Z"
        fill="#595959"
      />
      <path
        d="M7.75 5.25C7.75 5.44891 7.82902 5.63968 7.96967 5.78033C8.11032 5.92098 8.30109 6 8.5 6C8.69891 6 8.88968 5.92098 9.03033 5.78033C9.17098 5.63968 9.25 5.44891 9.25 5.25C9.25 5.05109 9.17098 4.86032 9.03033 4.71967C8.88968 4.57902 8.69891 4.5 8.5 4.5C8.30109 4.5 8.11032 4.57902 7.96967 4.71967C7.82902 4.86032 7.75 5.05109 7.75 5.25ZM8.875 7H8.125C8.05625 7 8 7.05625 8 7.125V11.375C8 11.4438 8.05625 11.5 8.125 11.5H8.875C8.94375 11.5 9 11.4438 9 11.375V7.125C9 7.05625 8.94375 7 8.875 7Z"
        fill="#595959"
      />
    </svg>
  );
};
