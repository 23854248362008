import React from "react";
import { ToolTip } from "../../assets/icons/Tooltip";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

const ToolTipText = ({ text }) => {
  return (
    <Tooltip
      placement="top"
      trigger={"hover"}
      overlay={text}
      overlayInnerStyle={{
        backgroundColor: "#000000",
        color: "#ffff",
        border: "none",
        fontSize: "14px",
      }}
      overlayClassName="tooltip_overlay_custom_class"
    >
      <span className="cursor-pointer">
        <ToolTip />
      </span>
    </Tooltip>
  );
};

export default ToolTipText;
