import * as React from "react"
const VsIcon
 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={61}
    fill="none"
    {...props}
  >
    <rect width={59} height={59} x={0.5} y={0.85} fill="url(#a)" rx={29.5} />
    <rect width={59} height={59} x={0.5} y={0.85} stroke="#F0F0F0" rx={29.5} />
    <rect width={59} height={59} x={0.5} y={0.85} stroke="url(#b)" rx={29.5} />
    <path
      fill="#1D0642"
      d="M23.302 37 19.09 24.04h1.953l3.591 11.061L28.27 24.04h1.953L26.011 37h-2.709Zm12.175.27c-.93 0-1.77-.159-2.52-.477a4.834 4.834 0 0 1-1.845-1.377c-.48-.6-.792-1.311-.936-2.133l1.962-.297c.198.792.612 1.41 1.242 1.854.63.438 1.365.657 2.205.657.522 0 1.002-.081 1.44-.243.438-.168.789-.405 1.053-.711.27-.312.405-.684.405-1.116a1.453 1.453 0 0 0-.46-1.089 1.801 1.801 0 0 0-.512-.351 3.844 3.844 0 0 0-.657-.261l-3.321-.981a8.023 8.023 0 0 1-.99-.369 3.906 3.906 0 0 1-.927-.603 2.957 2.957 0 0 1-.675-.927c-.174-.372-.261-.822-.261-1.35 0-.798.204-1.473.612-2.025.414-.558.972-.978 1.674-1.26.702-.288 1.488-.432 2.358-.432.876.012 1.659.168 2.349.468.696.3 1.272.732 1.728 1.296.462.558.78 1.236.954 2.034l-2.016.342a2.646 2.646 0 0 0-.576-1.251 2.884 2.884 0 0 0-1.08-.81 3.436 3.436 0 0 0-1.386-.297c-.48-.012-.921.06-1.323.216a2.36 2.36 0 0 0-.954.657 1.479 1.479 0 0 0-.351.972c0 .36.105.651.315.873.21.222.468.399.774.531.312.126.62.231.927.315l2.394.675c.3.084.639.198 1.017.342.384.138.753.333 1.107.585.36.252.657.588.89 1.008.235.414.352.936.352 1.566 0 .654-.132 1.23-.396 1.728a3.66 3.66 0 0 1-1.09 1.242c-.455.33-.983.579-1.583.747-.6.168-1.233.252-1.9.252Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={14}
        x2={46.5}
        y1={6.5}
        y2={53}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F5F5" />
        <stop offset={0.455} stopColor="#EBE6FF" />
        <stop offset={1} stopColor="#F5F5F5" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={63.587}
        x2={-14.809}
        y1={68.794}
        y2={31.378}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5E44FF" />
        <stop offset={0.172} stopColor="#F5F5F5" />
        <stop offset={0.556} stopColor="#3F2193" stopOpacity={0.54} />
        <stop offset={0.75} stopColor="#5E44FF" stopOpacity={0.66} />
        <stop offset={1} stopColor="#FAFAFA" />
      </linearGradient>
    </defs>
  </svg>
)
export default VsIcon

