import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { EnterpriseCheck } from "../../assets/icons/EnterpriseCheck";
import { FreeCheck } from "../../assets/icons/FreeCheck";
import { TeamCheck } from "../../assets/icons/TeamCheck";
import ToolTipText from "./TooltipText";

const TableBodyRowSection = ({
  tableBodyRowData,
  openFeature,
  activePlan,
  tableTheme,
  tabletLength,
  mobileLength,
  desktopLength,
  smallDesktoplength,
}) => {
  const table = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 400 });
  const isTablet = useMediaQuery({ maxWidth: 820 });
  const isSmallDesktop = useMediaQuery({ maxWidth: 1023 });

  const tableHeight = table?.current?.scrollHeight;
  const maxHeight = isMobile
    ? tableHeight / mobileLength
    : isTablet
    ? tableHeight / tabletLength
    : isSmallDesktop
    ? tableHeight / smallDesktoplength
    : tableHeight / desktopLength;

  const textColor = {
    purple: "text-purple-1700",
    green: "text-green-1100",
    black: "text-purple-1100",
  };

  return (
    <div
      className={`font-inter mt-2.5 overflow-hidden transition-all duration-500 ease-in-out`}
      ref={table}
      style={
        openFeature
          ? { maxHeight: tableHeight, opacity: 1 }
          : { maxHeight: maxHeight, opacity: 1 }
      }
      aria-expanded={openFeature}
    >
      {tableBodyRowData?.items?.map((item, index) => {
        const featureNameArray=item?.feature_name?.text?.trim().split(" ");
        const lastWord=featureNameArray?.pop();
        const featureName=featureNameArray?.join(" ");
        return (
          <React.Fragment key={index}>
            {item?.label?.text ? (
              <div className="flex">
                <div className="mt-2.5 flex items-center lg:pb-4 md:pb-3 pb-2.5 w-full max-w-[500px] border-r-0 border-[darkgray] lg:pl-7.5 lg:pr-0 sm:px-5 px-4">
                  <span
                    className={`sm:text-xl text-lg font-inter font-semibold ${textColor[tableTheme]}`}
                  >
                    {item?.label?.text}
                  </span>
                </div>
                <div className="justify-end hidden w-auto lg:flex lg:w-full">
                  <div className="sm:flex justify-center items-center py-2.5  xl:w-[234px] lg:w-[200px] w-full px-1.5 lg:border-x border-gray-1400" />
                  <div className="sm:flex justify-center items-center py-2.5  xl:w-[234px] lg:w-[200px] w-full px-1.5 lg:border-r border-gray-1400" />
                  <div className="sm:flex justify-center items-center py-2.5  xl:w-[234px] lg:w-[200px] w-full px-1.5" />
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between font-inter sm:justify-normal sm:hover:bg-gray-1500 lg:justify-items-end">
                <div className="lg:py-2.5 md:py-3 py-2.5 w-full lg:max-w-sm lg:pl-7.5 lg:pr-0 sm:px-5 pl-4 pr-0">
                  <p className="relative text-base text-gray-600 sm:max-w-md sm:font-medium">
                    <span>{featureName}</span>
                    <span className="whitespace-nowrap">{" " + lastWord} {!!item?.feature_name_tooltip?.text && (
                      <span className="inline-flex ml-1 tooltip_overlay_custom_class">
                        <ToolTipText text={item?.feature_name_tooltip?.text} />
                      </span>
                    )}
                    </span>
                  </p>
                </div>

                <div
                  className={`flex lg:w-full w-auto justify-end ${
                    item?.feature_name?.text?.length >= 50 && "lg:h-18"
                  }`}
                >
                  <div
                    className={`${
                      activePlan == 0 ? "flex" : "hidden"
                    } sm:flex justify-center items-center py-2.5 xl:max-w-[234px] lg:max-w-[200px] w-full px-1.5 lg:border-x border-gray-1400`}
                  >
                    {item?.free_column_text?.text === "check" ? (
                      <div className="flex justify-center table_text_width lg:w-auto">
                        <FreeCheck />
                      </div>
                    ) : (
                      <span className="flex justify-center text-sm text-center tracking-[-1px] font-medium text-gray-600 sm:text-base table_text_width lg:w-auto">
                        {item?.free_column_text?.text}
                      </span>
                    )}
                  </div>
                  <div
                    className={`${
                      activePlan == 1 ? "flex" : "hidden"
                    } sm:flex justify-center items-center py-2.5  xl:max-w-[234px] lg:max-w-[200px] w-full px-1.5 lg:border-r border-gray-1400`}
                  >
                    {item?.team_column_text?.text === "check" ? (
                      <div className="flex justify-center table_text_width lg:w-auto">
                        <TeamCheck />
                      </div>
                    ) : (
                      <span className="flex justify-center text-sm text-center tracking-[-1px] font-medium sm:text-base text-blue-1500 table_text_width lg:w-auto">
                        {item?.team_column_text?.text}
                      </span>
                    )}
                  </div>
                  <div
                    className={`${
                      activePlan == 2 ? "flex" : "hidden"
                    } sm:flex justify-center items-center py-2.5 max-w-none  xl:max-w-[234px] lg:max-w-[200px] w-full px-1.5`}
                  >
                    <span className="flex justify-center">
                      {item?.enterprise_column_text?.text === "check" ? (
                        <div className="flex justify-center table_text_width lg:w-auto">
                          <EnterpriseCheck />
                        </div>
                      ) : (
                        <span className="flex justify-center text-sm font-medium tracking-[-1px] sm:text-base text-center text-purple-1500 table_text_width lg:w-auto ">
                          {item?.enterprise_column_text?.text}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default TableBodyRowSection;
