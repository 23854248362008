import React, { useState } from "react";
import DemoModal from "../DemoModal";
import CustomButton from "../CustomButton";

const CTASection = ({
  heading,
  location,
  description,
  openModal,
  headingStyle,
  subHeadingStyle,
  buttonStyle,
}) => {
  return (
    <section className="max-w-lg mx-auto text-center text-gray-2800 lg:max-w-3xl">
      <h3
        className={`!text-2.5xl font-semibold  sm:font-bold lg:!text-4.5xl sm:!text-3.25xl sm:leading-10 text-2xl lg:mb-6 sm:mb-7 mb-5 font-manrope ${headingStyle}`}
      >
        {heading}
      </h3>
      <p
        className={`px-0 mb-8 text-sm font-inter sm:text-lg sm:mb-7 lg:px-18 ${subHeadingStyle}`}
      >
        {description}
      </p>
      <CustomButton
        onClick={openModal}
        text="Get started"
        buttonClass={`text-white dicom-btn mx-auto ${buttonStyle}`}
      />
    </section>
  );
};

export default CTASection;
