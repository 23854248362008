import React from "react";
export const EnterpriseCheck = () => {
  return (
    <svg
      width="129"
      height="28"
      viewBox="0 0 129 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-24 h-auto sm:w-32"
    >
      <path
        d="M58.5 13.3258L62.5 18.0011L70.5 9.42969"
        stroke="#5E44FF"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
