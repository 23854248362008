import React from "react";
import "swiper/css";
import "../../styles/page/annotation_pages.scss";
import defaultIcon from "../../assets/defaultAvatar.png";
import { Link } from "gatsby-link";

const CustomerStories = ({ data }) => {

  const renderCard = (
    {
      heading1,
      avatar,
      name,
      designation,
      company_name,
      description1,
      company_logo,
      cs_link,
    },
    index
  ) => {
    const CardContent = () => (
      <>
        <div key={index} className="flex flex-col justify-between">
          <div className="flex items-center sm:px-4 px-1.5 pb-3 gap-4 flex-row">
            <img
              src={avatar?.url || defaultIcon}
              loading="lazy"
              width={40}
              height={40}
              alt="avatar"
              className="rounded-full"
            />
            <div className="flex flex-col">
              <span className="text-sm sm:text-base text-[#1D1B20] font-medium tracking-[0.4px] font-inter ">
                {name?.text}
              </span>
              <span className="text-xs font-normal leading-4 text-gray-600 sm:text-sm max-h-4 tracking-tighter_1 font-inter">
                {designation?.text}
              </span>
              <span className="text-xs font-normal text-gray-600 sm:text-sm tracking-tighter_1">
                {company_name?.text}
              </span>
            </div>
          </div>
          <div className="flex flex-col justify-between sm:pt-2.5 px-5">
            <div className="flex-col justify-between block ">
              <p
                dangerouslySetInnerHTML={{ __html: heading1?.html }}
                className={`text-gray-600 sm:leading-tight min-h-[84px] block ${index === 1 ? `sm:max-w-[250px]` : "sm:max-w-[324px]"} sm:min-h-12 font-manrope customerCardHeading max-w-[214px] -tracking-[0.9px] text-lg sm:text-xl font-semibold`}
              />
              <p className="mt-3 text-sm font-normal text-gray-600 line-clamp-5 font-inter">
                {description1?.text}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end mt-5">
          <img
            src={company_logo?.url}
            loading="lazy"
            alt="avatar"
            className="max-w-[120px] max-h-8  px-1 py-1.5 mr-5"
          />
        </div>
      </>
    );

    return cs_link?.url ? (
      <Link
        key={index}
        to={cs_link.url}
        className="customer_story_card py-3 cursor-pointer justify-between bg-white rounded-xl sm:min-w-[364px] min-w-[254px] flex flex-col border border-solid border-gray-1600 ease-in-out duration-500"
      >
        <CardContent />
      </Link>
    ) : (
      <div
        key={index}
        className="customer_story_card py-3 justify-between bg-white rounded-xl sm:min-w-[364px] min-w-[254px] flex flex-col border border-solid border-gray-1600 ease-in-out duration-500"
      >
        <CardContent />
      </div>
    );
  };

  return (
    <section>
      {data?.primary?.section_heading?.text && (
        <div className="flex flex-col gap-2.5 pb-5 sm:pb-7">
          <h1 className="font-manrope text-2xl text-left sm:text-3.25xl font-semibold leading-10 text-purple-1100 -tracking-[1.8px]">
            {data?.primary?.section_heading?.text}
          </h1>
        </div>
      )}
      <div className="flex w-auto gap-3 overflow-x-auto sm:gap-6 scroll-smooth no-scrollbar tracking-tight_1 cs_slide_opacity_right">
        {data?.items?.map(renderCard)}
      </div>
    </section>
  );
};

export default CustomerStories;
