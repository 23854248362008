import React from "react";
import "../../styles/components/PricingPage/vsTableChart.scss";
import EncordWhite from "../../assets/icons/encord_white_logo.svg";
import GreenCheck from "../../assets/icons/greenCheck";
import VsIcon from "../../assets/icons/VsIcon";
import CustomButton from "../CustomButton";

const VsTableChart = ({ headingData, cardsData, openModal,buttonStyle }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-8 sm:gap-10">
      <div className="flex flex-col items-center gap-5">
        <p
          dangerouslySetInnerHTML={{
            __html: headingData?.section_heading?.html,
          }}
          className="vs_strong_style text-purple-1100 font-manrope text-2.5xl sm:text-4.5xl font-bold sm:leading-12 tracking-tightest_6 sm:tracking-tightest_4"
        />
        <p className="text-sm font-normal text-center text-gray-600 font-inter sm:text-lg tracking-tighter_2 sm:tracking-tightest">
          {headingData?.section_description?.text}
        </p>
        <CustomButton
          onClick={openModal}
          text="Get started"
          buttonClass={`text-white bg-purple-1500 hover:bg-[#5E24FF] ${buttonStyle} mx-auto`}
        />
      </div>
      <div className="flex flex-col-reverse items-center mt-4 sm:flex-row">
        {/* VS Card */}
        <div className="flex flex-col bg-[#fff] rounded-2.5xl border border-gray-1400 mx-5 sm:mx-0">
          <p className="text-xs font-semibold py-2.5 sm:py-4 px-2.5 sm:px-3.5 tracking-tighter border-b border-b-gray-1600 rounded-t-2.5xl bg-gray-2800 text-gray-2500 font-manrope lg:text-lg lg:tracking-tightest">
            {cardsData[0]?.primary?.label?.text}
          </p>
          <div className="p-2.5 sm:p-3.5 flex flex-col gap-2.5">
            {cardsData[0]?.items?.map((item, index) => (
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: item?.item_text?.html,
                }}
                className="max-w-[348px] text-xs font-normal tracking-tighter py-[7px] sm:py-2.5 border-b border-b-gray-1400 itemsList_strong_weight last:border-b-0 text-gray-2700 font-inter lg:text-base lg:tracking-tighter_3"
              />
            ))}
          </div>
        </div>
        <div className="block sm:hidden relative -my-2.5 self-center">
          <VsIcon />
        </div>
        {/* Encord Card */}
        <div className="flex flex-col bg-[#fff] rounded-2.5xl border border-gray-1600 encord_box_shadow sm:-ml-3.5">
          <div className="self-center py-2.5 lg:py-3.5 px-5 lg:px-6 encord_card_logo_bg rounded-full -mt-6">
            <EncordWhite />
          </div>
          <div className="pb-2.5 px-3.5 sm:px-[18px] lg:px-2.5 flex flex-col gap-2.5 last_border_bottom">
            {cardsData[1]?.items?.map((item, index) => (
              <div className="px-2 lg:px-5 flex items-center gap-2 lg:gap-2.5 last:border-b-0 check_icon_width">
                <GreenCheck />
                <div
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item?.item_text?.html,
                  }}
                  className="text-sm font-normal flex-1 lg:flex-none max-w-[370px] tracking-tighter_2 py-2.5 border-b border-b-gray-1400 itemsList_strong_weight text-gray-2700 font-inter lg:text-lg lg:tracking-tightest"
                />
              </div>
            ))}
          </div>
          <div className="self-center py-1.5 lg:py-2.5 highlighted_label_bg w-full text-center mb-[18px] lg:mb-8 text-white font-manrope text-base lg:text-2xl tracking-tighter font-semibold lg:tracking-tightest_5">
            {cardsData[1]?.primary?.label?.text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VsTableChart;
