import React from "react";
import CustomButton from "../CustomButton";

const themeHoverText = {
  gray: "group-hover:text-gray-2700",
  blue: "group-hover:text-blue-1300",
  purple: "group-hover:text-purple-1500",
};

const themeText = {
  gray: "text-gray-2700",
  blue: "text-blue-1300",
  purple: "text-purple-1500",
};

const themeFillHover = {
  gray: "group-hover:fill-gray-2700 fill-gray-600 duration-300 ease-in-out",
  blue: "group-hover:fill-blue-1300 fill-blue-1300 lg:fill-gray-600 duration-300 ease-in-out",
  purple:
    "group-hover:fill-purple-1500 fill-purple-1500 lg:fill-gray-600 duration-300 ease-in-out",
};

const themeBackground = {
  gray: "text-gray-2500 bg-gray-2800 border border-gray-2200",
  blue: "bg-blue-1300 text-white border border-blue-1300",
  purple: "bg-purple-1500 text-white border border-purple-1500",
};

const themeAddOnHover = {
  gray: "group-hover:bg-gray-1600 group-hover:text-gray-2500",
  blue: "group-hover:bg-blue-1600 group-hover:text-blue-1500",
  purple: "group-hover:bg-purple-100 group-hover:text-purple-1500",
};
const themeAddOnHoverButton = {
  gray: "hover:bg-gray-1600 hover:text-gray-2500",
  blue: "hover:bg-blue-1500 hover:text-white",
  purple: "hover:bg-purple-1800 hover:text-white",
};
const themeAddOn = {
  gray: "bg-gray-1600 text-gray-2500",
  blue: "bg-blue-1600 text-blue-1500",
  purple: "bg-purple-100 text-purple-1500",
};

const PricingCard = ({
  heading,
  description,
  items,
  label,
  subLabel,
  icon,
  onClick,
  buttonText,
  themeColor,
  logo1,
  logo2,
  logo3
}) => {
  const logos=[logo1,logo2,logo3]
  return (
    <div className="plan_card_bg border border-gray-1500 rounded-2.5xl">
      <section
        onClick={onClick}
        className="rounded-2.5xl rotating-border flex flex-col bg-white sm:px-7 px-3.5 lg:py-12 py-7 group hover:cursor-pointer h-full"
      >
        {icon?.url && (
          <img
            src={icon?.url}
            width={0}
            height={0}
            alt={icon?.alt || "Pricing card icon"}
            className="w-7.5 h-auto mb-3.5 custom_grayScale group-hover:!grayscale-0 group-hover:!opacity-100 duration-300 ease-in-out"
          />
        )}

        <div
          className={`lg:text-gray-2500 duration-300 ease-in-out ${
            themeColor === "gray" ? "text-gray-2500" : themeText[themeColor]
          } font-bold text-3.25xl leading-10 mb-2.5 ${
            themeHoverText[themeColor]
          }`}
        >
          {heading}
        </div>
        <p className="text-base text-gray-600 font-inter mb-7 group-hover:text-gray-2700">
          {description}
        </p>
        <div className="flex justify-center">
          <CustomButton
            onClick={onClick}
            text={buttonText}
            buttonClass={`${themeBackground[themeColor]} ${themeAddOnHoverButton[themeColor]} text-lg py-2.5 focus:outline-none`}
            isFullWidth
          />
        </div>
        <div className="flex items-center w-full text-xs font-semibold text-center text-gray-600 uppercase lg:gap-x-0 sm:gap-x-4 lg:py-6 py-7 tracking-widest_1">
          <hr
            className="h-[1px] border-0 lg:w-1/3 sm:w-2/3 xs:w-1/3 w-1/4"
            color="#8C8C8C"
          />
          <div className="w-2/3 uppercase sm:w-1/2 lg:w-3/4">{label}</div>
          <hr
            className="h-[1px] border-0 w-1/4 xs:w-1/3 sm:w-2/3 lg:w-1/3"
            color="#8C8C8C"
          />
        </div>
        {subLabel && (
          <div
            className={`font-inter lg:mb-4 ${
              subLabel && "mb-5"
            } text-base text-gray-600 font-medium ${
              themeHoverText[themeColor]
            } duration-300 ease-in-out`}
          >
            {subLabel}
          </div>
        )}
        <ul className="flex flex-col text-base text-gray-600 font-inter">
          {items?.map((plan, index) => {
            return (
              <li
                key={`free_${index}`}
                className={`flex flex-wrap gap-y-2 ${
                  index !== items.length - 1 && "mb-5"
                } items-center`}
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={themeFillHover[themeColor]}
                    d="M8.6549 0.196777C4.227 0.196777 0.636719 3.78706 0.636719 8.21496C0.636719 12.6429 4.227 16.2331 8.6549 16.2331C13.0828 16.2331 16.6731 12.6429 16.6731 8.21496C16.6731 3.78706 13.0828 0.196777 8.6549 0.196777ZM12.1181 5.59652L8.34885 10.8227C8.29617 10.8962 8.22672 10.9561 8.14626 10.9974C8.0658 11.0388 7.97665 11.0603 7.88619 11.0603C7.79574 11.0603 7.70659 11.0388 7.62613 10.9974C7.54567 10.9561 7.47622 10.8962 7.42354 10.8227L5.19169 7.72993C5.12368 7.63507 5.19169 7.50263 5.30803 7.50263H6.14743C6.32998 7.50263 6.50359 7.59033 6.61098 7.74067L7.8853 9.50896L10.6988 5.60726C10.8062 5.45871 10.978 5.36922 11.1624 5.36922H12.0018C12.1181 5.36922 12.1861 5.50166 12.1181 5.59652V5.59652Z"
                    fill="url(#paint0_linear_1630_86387)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1630_86387"
                      x1="8.6549"
                      y1="0.196777"
                      x2="8.6549"
                      y2="16.2331"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop
                        offset="1"
                        stop-color="#595959"
                        className="hidden lg:block"
                      />
                    </linearGradient>
                  </defs>
                </svg>
                <span className="ml-2.5 group-hover:text-gray-2700">
                  {plan?.list_item?.text}
                </span>
                {plan?.add_on_text?.text && (
                  <span
                    className={`ml-3 px-2.5 py-1.5 bg-blue-1600 text-blue-1500 ${themeAddOn[themeColor]} duration-300 ease-in-out rounded-full uppercase text-xs font-semibold -tracking-tight_1 font-manrope ${themeAddOnHover[themeColor]}`}
                  >
                    {plan.add_on_text.text}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
        {logos[0]?.url && (
          <div className="flex flex-col pt-10 sm:pt-7.5 lg:pt-10 gap-3.5 mt-auto">
            <p className="text-xs font-semibold text-gray-600 uppercase font-manrope -tracking-tightest_5">
              Used by
            </p>
            <div className="flex items-center gap-3.5 flex-wrap xl:flex-nowrap">
              {logos.map((image, index) => (
                <img
                  key={index}
                  className="object-scale-down h-4 lg:grayscale group-hover:grayscale-0 max-w-[126px]"
                  src={image.url}
                  alt={image.alt || "brand logo"}
                  width={image.dimensions?.width || ''}
                  height={image.dimensions?.height|| ''}
                />
              ))}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default PricingCard;
